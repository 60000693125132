<template>
  <div class="registration-step-1-block">
    <div class="login-block-container">
      <div class="reg-step-form">
        <h3>{{ $lang.app.reset_account }}</h3>
        <p>{{ $lang.app.email_success_des }} {{ $route.query.email }}</p>
        <div class="login-control single-control">
          <button class="blue-button" @click="$router.push('/')">{{ $lang.app.to_main }}</button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'EmailSuccess',
}
</script>
<style>
@import '../../assets/styles/form.css';
@import '../../assets/styles/auth.css';
</style>